import React from "react"

const SliderNavigation = ({ onNext, onPrev }) => (
  <div>
    <div
      className={"hidden laptop:grid absolute h-full w-5/6 z-10 grid-cols-2"}
    >
      <button
        className={"col-span-1 opacity-0 swiper-prev"}
        type="button"
        onClick={onPrev}
      >
        {" "}
      </button>
      <button
        className={"col-span-1 opacity-0 swiper-next"}
        type="button"
        onClick={onNext}
      >
        {" "}
      </button>
    </div>
    <div
      className={
        "hidden tablet:block fixed z-40 ml-2 mb-16 bottom-0 left-0 tablet:ml-5"
      }
    >
      <button
        type="button"
        className={"p-3 hover:underline outline-none"}
        onClick={onPrev}
      >
        prev
      </button>
      /
      <button
        type="button"
        className={"p-3 hover:underline outline-none"}
        onClick={onNext}
      >
        next
      </button>
    </div>
  </div>
)

export default SliderNavigation
